import { Typography, Grid } from '@mui/material';
import { Box } from '@mui/system';
import Constants from './Constants';
import codeImg from './code.jpg';
import { initialHeight } from './Main';
import AnimatedText from './AnimatedText';
import ContactButton from './ContactButton';
const styles = {
  heroImage: {
    backgroundImage: `url(${codeImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'left center',
  },
};
const HeadlineOptions = [
  {
    heading: 'Startup / Small Business Specialist',
    subheadings: [
      'Get the strategic tech leadership your business needs, without the full-time commitment.',
      'Drive your technology vision forward with expert fractional CTO services.',
      'From technology audits to strategy formulation, we offer comprehensive services for startups and small businesses.',
    ],
  },
  {
    heading: 'Full-Stack Expertise, Full-Scale Delivery',
    subheadings: [
      'Supporting your startup journey from ideation to deployment, across multiple sectors.',
      'Leadership in requirements gathering, design decisions, and high-quality code.',
      'Trust in our comprehensive skills for building, testing, and optimizing web applications.',
    ],
  },
  {
    heading: 'Strategic Project Management',
    subheadings: [
      'Turn your vision into a structured plan with our expert project management.',
      'From JIRA to Trello, we have the tools to keep your teams organized and focused.',
      'Execute your projects on time and within scope, without sacrificing quality.',
    ],
  },
  {
    heading: 'Transformative Tech Solutions',
    subheadings: [
      'We specialize in tech that makes an impact, from business efficiency to societal change.',
      'Our mission is to create innovative solutions that are as transformative as they are efficient.',
      'Redefining the scope of what technology can achieve, for businesses and society alike.',
    ],
  },
  {
    heading: 'Mobile Web Development & Beyond',
    subheadings: [
      'State-of-the-art mobile web apps to keep you ahead in the game.',
      'Not just mobile: we bring expertise across a range of technologies and platforms.',
      'Your audience is on multiple devices; we make sure you are too.',
    ],
  },
  {
    heading: 'Deployment & Monitoring, Mastered',
    subheadings: [
      'From Docker to AWS, we have the tools to ensure seamless operations.',
      'Mastering the technical landscape to bring your solutions to the world.',
      'Expertise that keeps your web applications performing at their peak.',
    ],
  },
  {
    heading: 'Cloud Hosting & More',
    subheadings: [
      'Beyond hosting: Offering a suite of services to support your business.',
      'Your apps, securely and reliably hosted, with the expertise you need.',
      'Clear pricing, expert service, and the freedom to focus on your mission.',
    ],
  },
];

export default function Hero() {
  const headingOptions = HeadlineOptions.map((ho) => ho.heading);
  const subheadingOptions = HeadlineOptions.map((ho) => ho.subheadings).flat();
  const subHeadingTime = 4000;
  const animationTime = 25;
  const headingTime = (subHeadingTime + animationTime) * 3 - animationTime;
  return (
    <Box
      id="hero"
      minHeight={initialHeight}
      display="flex"
      flexDirection="column"
    >
      <Grid
        container
        flex={1}
        spacing={2}
        mt={0}
        height={{ xs: 'auto', md: '100%' }}
      >
        <Grid sm={12} item={true} md={6} bgcolor="white" borderRadius={3}>
          <Box
            minHeight="100%"
            display="flex"
            alignContent="center"
            flexDirection="row"
          >
            <Box
              width={{ xs: '100%', md: '75%' }}
              margin="auto"
              textAlign={{ xs: 'center', md: 'left' }}
              p={1}
            >
              <AnimatedText
                inputValues={headingOptions}
                typographyVariant="h1"
                waitTime={headingTime}
              />
              <AnimatedText
                inputValues={subheadingOptions}
                typographyVariant="h4"
                waitTime={subHeadingTime}
              />
              <Typography
                variant="h2"
                fontSize={Constants.responsiveFontSizes.h2}
              >
                <ContactButton />
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} item={true} md={6} style={styles.heroImage}></Grid>
      </Grid>
    </Box>
  );
}
