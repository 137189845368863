import { AppBar, Toolbar, Box } from '@mui/material';
import ContactButton from './ContactButton';
import LogoImg from './logo.svg';
export const Header = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Box component="img" src={LogoImg} alt="Logo" height="95px" p={1} />
        <Box
          flexGrow={1}
          textAlign="right"
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            display: {
              xs: 'none',
              md: 'flex',
            },
          }}
        >
          <ContactButton />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
