import { GitHub, LinkedIn } from '@mui/icons-material';
import { Box, IconButton, Link, Typography } from '@mui/material';
import Hero from './Hero';
import { Testmonials } from './Testimonials';
import TechServices from './TechServices';
import EthosSection from './EthosComponent';
import { ContactForm } from './ContactForm';
export const initialHeight = {
  xs: 'calc(100vh - 88px)',
  sm: 'calc(100vh - 92px)',
};
export const phoneNumber = '541-423-3114';
export const MainPhone = () => {
  return <>{phoneNumber}</>;
};
function Copyright() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ pt: 4 }}
    >
      Copyright&nbsp;
      {new Date().getFullYear()}&nbsp;&copy;&nbsp;
      <Link color="inherit" href="https://berad.tech">
        Be Rad Technology LLC
      </Link>
    </Typography>
  );
}
function Social() {
  return (
    <Typography variant="body1" align="center" pt={2}>
      <IconButton href="https://www.linkedin.com/in/bradwedell/">
        <LinkedIn sx={{ fontSize: '50px' }} />
      </IconButton>
      <IconButton href="https://github.com/streetlogics/">
        <GitHub sx={{ fontSize: '50px' }} />
      </IconButton>
    </Typography>
  );
}

export const Main = () => {
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          mb: 4,
          minHeight: initialHeight,
        }}
      >
        <Hero />
        <EthosSection />
        <TechServices />
        <Testmonials />
        <ContactForm />
        <Copyright />
        <Social />
      </Box>
    </Box>
  );
};
