import { Button } from '@mui/material';
import ReactGA from 'react-ga4';

const ContactButton = (props: any) => {
  const { size } = props;
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => {
        ReactGA.event('click-contact');
      }}
      href="#contact"
      size={size ? size : 'medium'}
      sx={{ fontSize: size === 'large' ? '30px' : '' }}
    >
      Contact Us
    </Button>
  );
};
export default ContactButton;
