import { Star } from '@mui/icons-material';
import { Box, Container, Link, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Constants from './Constants';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const testimonials = [
  {
    message: `Brad was excellent at understanding the big picture and tackling projects head on. His breadth of skill, go-to attitude, and willingness to learn new technologies quickly made him an invaluable team member in our fast paced demanding environment. I wouldn't hesitate to recommend Brad for any technology problem that needs to be solved.`,
    from: 'Zach Hendershot, Founder Crux Studio',
    url: 'https://www.linkedin.com/in/bradwedell/details/recommendations/',
  },
  {
    message:
      'Brad is a fantastic developer and team player. He has the ability to quickly grasp complex concepts and architect quality solutions. It was a pleasure to work with him!',
    from: 'Cooper McGoodwin, VP Product',
    url: 'https://www.linkedin.com/in/bradwedell/details/recommendations/',
  },
  {
    message: `Brad is a pleasure to work with. He not only tackles problems in a thoughtful way, with an eye for performance and long-term maintainability, but he does so with a ton of entrepreneurial enthusiasm. Brad's experience as an entrepreneur himself makes him feel more like a partner than an employee or consultant.`,
    from: 'Andrew Cohen, CEO Brainscape',
    url: 'https://www.linkedin.com/in/bradwedell/details/recommendations/',
  },
  {
    message: `Brad is rad.  After joining in the summer of 2019, Brad very quickly became a core member of the team. He was handed a three-year-old Rails codebase, was able to find his footing quickly, and became immediately impactful. When it came time to build some more modern messaging features into the app he championed the first use of React at our company, learning it on the fly building a product that has been used by hundreds of thousands of people to send millions of messages.`,
    from: 'Ryan Angilly on LinkedIn',
    url: 'https://www.linkedin.com/in/bradwedell/details/recommendations/',
  },
];

const RedStar = () => (
  <Star
    sx={{
      color: 'secondary.main',
      fontSize: '38px',
      lineHeight: '1em',
    }}
  />
);

export function Testmonials() {
  return (
    <Container maxWidth="lg">
      <Typography
        variant="h2"
        fontSize={Constants.responsiveFontSizes.h2}
        textAlign="center"
        mb={2}
        mt={0}
      >
        Highly Recommended
        <br />
        <Box mt={1}>
          <RedStar />
          <RedStar />
          <RedStar />
          <RedStar />
          <RedStar />
        </Box>
      </Typography>

      <Grid container spacing={4} mb={1}>
        {testimonials.map((testimonial) => {
          return (
            <Grid xs={12} md={6} key={testimonial.message}>
              <Testimonial {...testimonial} />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

const Testimonial = (props: { message: string; from: string; url: string }) => {
  const { message, from, url } = props;
  return (
    <Paper
      sx={{
        padding: 2,
        height: '100%',
        color: '#fff',
        backgroundColor: 'secondary.main',
      }}
    >
      <Box display="flex" flexDirection={'column'} height="100%">
        <FormatQuoteIcon sx={{ fontSize: '28px' }} />
        <Box sx={{ fontFamily: '"Faustina", serif', fontSize: '18px' }}>
          {message}
        </Box>
        <Box
          flex={1}
          display="flex"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          -&nbsp;
          <Link color="primary" fontWeight="bold" href={url} fontSize="12px">
            {from}
          </Link>
        </Box>
      </Box>
    </Paper>
  );
};
