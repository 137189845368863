import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import '@fontsource/sintony';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import ReactGA from 'react-ga4';
import './App.scss';
import { Header } from './Header';
import { Main } from './Main';

ReactGA.initialize('G-72EHKGE51B');
export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: '"Sintony", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: '"Faustina", serif',
      fontWeight: '800',
      marginBottom: '14px',
    },
    h2: {
      fontFamily: '"Faustina", serif',
      marginBottom: '12px',
      fontWeight: '800',
      fontSize: '3rem',
    },
    h3: {
      marginBottom: '12px',
      fontSize: '2rem',
    },
    h4: {
      marginBottom: '12px',
      fontSize: '1.5rem',
    },
  },
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#000',
    },
    secondary: {
      main: '#07B1D8',
      contrastText: '#fff',
    },
  },
};
const mdTheme = createTheme(themeOptions);

function App() {
  return (
    <ThemeProvider theme={mdTheme}>
      <ScopedCssBaseline>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Header />
          <Main />
        </LocalizationProvider>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}

export default App;
