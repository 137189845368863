import {
  Web,
  Devices,
  Cloud,
  SupervisorAccount,
  Assignment,
  Architecture,
} from '@mui/icons-material';
import Lock from '@mui/icons-material/Lock';
import Brush from '@mui/icons-material/Brush';
import Transform from '@mui/icons-material/Transform';
import { Paper } from '@mui/material';

interface Service {
  icon: JSX.Element;
  title: string;
  content: JSX.Element;
}

const services: Service[] = [
  {
    icon: <Web />,
    title: 'Web Development',
    content: (
      <Paper elevation={3} sx={{ px: 2, py: 0.5 }}>
        <p>
          Our web development services are at the forefront of creating
          innovative, scalable websites and web applications that drive digital
          transformation. Specializing in responsive design, our solutions range
          from e-commerce platforms to custom web applications, all designed to
          enhance user experience and elevate your online presence. With a focus
          on modern technologies and SEO optimization, we ensure your website
          not only looks great but ranks well on search engines.
        </p>
        <ul>
          <li>SEO-Friendly Web Design</li>
          <li>Custom Web Application Development</li>
          <li>Content Management Systems</li>
          <li>E-Commerce Migrations</li>
        </ul>
      </Paper>
    ),
  },
  {
    icon: <Devices />,
    title: 'Mobile Web Apps',
    content: (
      <Paper elevation={3} sx={{ px: 2, py: 0.5 }}>
        <p>
          Capture the growing mobile audience with our mobile web app
          development services. By emphasizing responsive design and
          cross-platform accessibility, we ensure your application provides an
          exceptional user experience on any device. From strategy to
          deployment, our team focuses on creating mobile web applications that
          not only meet your business needs but also enhance your mobile SEO
          strategy.
        </p>
      </Paper>
    ),
  },
  {
    icon: <Cloud />,
    title: 'Cloud Solutions',
    content: (
      <Paper elevation={3} sx={{ px: 2, py: 0.5 }}>
        <p>
          Leverage the power of cloud computing with our comprehensive cloud
          solutions. From cloud migration to managed services, our team helps
          you navigate the complexities of the cloud, optimizing your IT
          infrastructure for enhanced efficiency and scalability. Our solutions
          are tailored to meet your specific needs, ensuring a seamless
          transition to the cloud.
        </p>
        <ul>
          <li>Cloud Migration Services</li>
          <li>Cloud Infrastructure Management</li>
          <li>Hybrid Cloud Solutions</li>
        </ul>
      </Paper>
    ),
  },
  {
    icon: <SupervisorAccount />,
    title: 'Consulting & Strategy',
    content: (
      <Paper elevation={3} sx={{ px: 2, py: 0.5 }}>
        <p>
          Transform your technology landscape with our strategic consulting
          services. Our experts provide guidance on leveraging technology to
          achieve your business goals. We focus on delivering customized
          solutions that drive innovation, efficiency, and growth, ensuring you
          stay ahead in the digital era.
        </p>
      </Paper>
    ),
  },
  {
    icon: <Assignment />,
    title: 'Project Management',
    content: (
      <Paper elevation={3} sx={{ px: 2, py: 0.5 }}>
        <p>
          Achieve project success with our project management services.
          Specializing in IT projects, our team applies best practices and
          methodologies to ensure your projects are delivered on time, within
          budget, and to your specifications. From initiation to closure, we
          provide the expertise and tools necessary to manage complex projects,
          facilitating seamless communication and collaboration across all
          stakeholders.
        </p>
      </Paper>
    ),
  },
  {
    icon: <Architecture />,
    title: 'Software Architecture & Design',
    content: (
      <Paper elevation={3} sx={{ px: 2, py: 0.5 }}>
        <p>
          Design robust, scalable software systems with our software
          architecture and design services. Our approach focuses on creating
          architectures that support your business needs while ensuring
          flexibility and scalability. From microservices to monolithic designs,
          we tailor our solutions to support your software development
          lifecycle, integrating best practices in software design to build
          high-quality applications.
        </p>
        <ul>
          <li>Microservices Architecture</li>
          <li>System Integration Strategies</li>
          <li>Performance Optimization</li>
          <li>Deployment Processes</li>
          <li>Automated Testing</li>
        </ul>
      </Paper>
    ),
  },
  {
    icon: <Brush />,
    title: 'Creative & Design Services',
    content: (
      <Paper elevation={3} sx={{ px: 2, py: 0.5 }}>
        <p>
          Enhance your brand's visual appeal with our creative and design
          services. Our team of designers specializes in creating compelling
          visual content that captures your brand's essence. From logo design to
          comprehensive brand identity packages, we deliver creative solutions
          that stand out in the digital landscape, engaging your audience and
          building brand recognition.
        </p>
      </Paper>
    ),
  },
  {
    icon: <Lock />,
    title: 'Cybersecurity Services',
    content: (
      <Paper elevation={3} sx={{ px: 2, py: 0.5 }}>
        <p>
          Secure your digital assets with our advanced cybersecurity services.
          Our comprehensive approach to security covers everything from risk
          assessment to incident response, ensuring your business is protected
          against evolving cyber threats. With a focus on prevention, we
          implement state-of-the-art security measures to safeguard your
          information and maintain compliance with industry standards.
        </p>
        <ul>
          <li>Threat Detection and Response</li>
          <li>Data Protection and Privacy</li>
        </ul>
      </Paper>
    ),
  },
  {
    icon: <Transform />,
    title: 'Process Automation Consulting',
    content: (
      <Paper elevation={3} sx={{ px: 2, py: 0.5 }}>
        <p>
          Accelerate your digital transformation with our consulting services.
          We guide businesses through the process of integrating automation
          technology into all areas of their operations, fundamentally changing
          how they operate and deliver value to customers. Our expertise in
          digital strategy, technology, and change management drives your
          transformation journey, enabling innovation and competitive advantage.
        </p>
      </Paper>
    ),
  },
];

export default services;
