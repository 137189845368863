import React, { useState, useEffect } from 'react';
import { Typography, Fade, Box } from '@mui/material';
import Constants from './Constants';

type PropTypes = {
  inputValues: string[];
  typographyVariant: 'h1' | 'h4';
  animateTime?: number;
  waitTime?: number;
};

const AnimatedText = ({
  inputValues,
  typographyVariant,
  animateTime = 1000,
  waitTime = 5000,
}: PropTypes) => {
  const [index, setIndex] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isFadingIn, setIsFadingIn] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsFadingOut(true);
      const nextIndex = (index + 1) % inputValues.length;
      setIndex(nextIndex);
      setTimeout(() => {
        setIsFadingOut(false);
        setIsFadingIn(true);
        setTimeout(() => {
          setIsFadingIn(false);
        });
      }, animateTime);
    }, waitTime);
    return () => clearInterval(intervalId);
  }, [index, inputValues, animateTime, waitTime]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        height: '100%',
        maxWidth: '100%',
      }}
    >
      {inputValues.map((value, idx) => {
        const prevIndex = index - 1 >= 0 ? index - 1 : inputValues.length - 1;
        const isShown =
          (!isFadingOut && idx === index) || (isFadingOut && idx === prevIndex);
        const animateIn = !isFadingIn && idx === index;
        return (
          <Box width="100%" display={isShown ? 'block' : 'none'} key={idx}>
            <Fade in={animateIn} timeout={animateTime}>
              <Typography
                variant={typographyVariant}
                fontSize={Constants.responsiveFontSizes[typographyVariant]}
              >
                {value}
              </Typography>
            </Fade>
          </Box>
        );
      })}
    </Box>
  );
};

export default AnimatedText;
