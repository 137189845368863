import React from 'react';
import { Typography, Box, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import services from './ServicesList';
import Constants from './Constants';

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: '1rem',
  margin: '6px 0',
  [theme.breakpoints.down('md')]: {
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  marginRight: '2px',
  color: theme.palette.secondary.contrastText,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
  },
  '&.Mui-focusVisible': {
    backgroundColor: theme.palette.action.focus,
  },
}));

const TechServices = () => {
  const [value, setValue] = React.useState('0'); // Note: TabContext expects a string value

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box width={{ xs: '100%', lg: '70%' }} pl={{ xs: 1 }} marginX={'auto'}>
      <Typography
        variant="h2"
        textAlign="center"
        fontSize={Constants.responsiveFontSizes.h2}
      >
        Our Services
      </Typography>
      <TabContext value={value}>
        <Box display="flex" alignItems="center">
          <Box width={{ xs: '100px', sm: '20%' }}>
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="secondary"
              textColor="inherit"
              aria-label="tech services tabs"
              orientation={'vertical'}
              sx={{ height: 300 }}
            >
              {services.map((service, index) => (
                <StyledTab
                  label={service.title}
                  value={String(index)}
                  key={index}
                  wrapped
                />
              ))}
            </TabList>
          </Box>
          {services.map((service, index) => (
            <TabPanel
              value={String(index)}
              key={index}
              sx={{ width: '80%', padding: 1 }}
            >
              <Typography
                component="div"
                fontSize={{ xs: '.75rem', md: '1rem' }}
              >
                {service.content}
              </Typography>
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </Box>
  );
};

export default TechServices;
