// Importing required components and icons
import { Typography, Box, Grid, Paper } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Constants from './Constants';

interface EthosSectionProps {}

const EthosSection: React.FC<EthosSectionProps> = () => {
  return (
    <Box
      id="ethos-section"
      padding={3}
      maxWidth="100%"
      width={{ xs: '100%', lg: '70%' }}
      mx="auto"
    >
      <Typography
        variant="h2"
        textAlign="center"
        mb={3}
        fontSize={Constants.responsiveFontSizes.h2}
      >
        Our Radical Ethos
      </Typography>

      <Grid container spacing={4}>
        {[
          {
            title: 'Technology as a Tool for Good',
            icon: <PublicIcon style={{ fontSize: 60 }} color="secondary" />,
            text: "At Be Rad Technology, we don't just see technology as a tool but as an extension of human potential. We aim to harness it to tackle global challenges ranging from ecological conservation to plant-based medicines. The goal is to create a better, more sustainable world for future generations.",
          },
          {
            title: 'Innovation and Equality for All',
            icon: <EqualizerIcon style={{ fontSize: 60 }} color="secondary" />,
            text: 'We believe in a decentralized approach to innovation, where technology empowers, rather than oppresses. This involves focusing on bottom-up, collaborative systems that level the playing field, making sure that no voice is too small to be heard.',
          },
          {
            title: 'Empowering Heart-Driven Leaders',
            icon: (
              <VerifiedUserIcon style={{ fontSize: 60 }} color="secondary" />
            ),
            text: 'We believe in servant leadership, where the role of a leader is not to dictate but to elevate. In fostering environments where the best ideas rise organically from those closest to the issues at hand, we help to humanize technology and put people first in every solution we create.',
          },
        ].map((ethos, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Paper
              elevation={3}
              style={{
                padding: '16px',
                textAlign: 'center',
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                {ethos.icon}
                <Typography
                  variant="h4"
                  fontSize={Constants.responsiveFontSizes.h4}
                >
                  {ethos.title}
                </Typography>
                <Typography variant="body1">{ethos.text}</Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* <Paper
        elevation={3}
        style={{
          padding: '16px',
          marginTop: '32px',
        }}
      >
        <Typography variant="h2" textAlign="center">
          Together, Let's Be the Change
        </Typography>
        <Typography variant="body1" paddingTop={2} paddingBottom={2}>
          If you're a nonprofit, artist, social entrepreneur, or visionary, we
          invite you to join hands with us. Together, we can break down the
          barriers that limit technological progress to the privileged few, and
          open up avenues for meaningful, worldwide change.
        </Typography>
        <Typography textAlign="center">
          <ContactButton size="medium" />
        </Typography>
      </Paper> */}
    </Box>
  );
};

export default EthosSection;
